"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function setObjectByPath(object, path, value) {
  var keys = path.split('.');
  var current = object;
  for (var i = 0; i < keys.length - 1; i++) {
    var key = keys[i];
    if (current[key] === undefined) {
      current[key] = {}; // Create a new object if the path does not exist
    }
    current = current[key];
  }
  // Set the final key to the value
  current[keys[keys.length - 1]] = value;
  return keys[0];
}
var _default = exports["default"] = setObjectByPath;