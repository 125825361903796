import React from "react";
import {Image} from "nq-component";
import barangays from "./barangays.json";


const defaultProps = {
    width: "56mm",
    height: "87mm",
    primaryColor: "#ffffff",
    secondaryColor: "#ffffff",
    frontImage: "/front-id.png",
    backImage: "/back-id.png"

};

function Id({width, height, primaryColor, secondaryColor, frontImage, backImage, object, position}) {
    const renderFront = () => {
        const style = {
            width: "100%",
            height: "100%",
            backgroundImage: `url('${frontImage}'),linear-gradient(to right,${primaryColor}, ${secondaryColor || primaryColor})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            paddingTop: "102px"
        };
        const name = `${object.last_name} ${object.first_name}`;
        return (<div
            className="d-flex flex-column px-3"
            style={style}>
            <div className="text-center">
                <Image
                    className="mx-auto"
                    width="90px"
                    height="90px"
                    fit
                    rounded
                    src={object.picture}/>
                <h6 className="fw-bold mb-1 mt-3 text-uppercase"
                    style={{fontSize: name.length > 20 ? "10px" : "13px"}}>{name}</h6>
                <h6 className="fw-bold mb-0" style={{fontSize: "10px"}}>{object.position || position}</h6>
                <h6 className="mb-0 text-uppercase fw-bold"
                    style={{fontSize: "9px"}}>{barangays[object.barangay] || object.barangay}</h6>
                <h6 className="mb-0" style={{fontSize: "7px"}}>{object.province}</h6>
                <h6 className="mb-0" style={{fontSize: "7px"}}>{object.region}</h6>
            </div>
        </div>)

    };

    const renderBack = () => {
        const style = {
            width: "100%",
            height: "100%",
            backgroundImage: `url('${backImage}'),linear-gradient(to right,${primaryColor}, ${secondaryColor || primaryColor})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            padding: "7px"
        };
        return (<div
            className="d-flex flex-column"
            style={style}>
        </div>);

    };
    const style = {
        width: width, height: height,
    }
    return (<>
        <div style={style}>
            {renderFront()}
        </div>
        <div style={style}>
            {renderBack()}
        </div>
    </>);
}

Id.defaultProps = defaultProps;
export default Id
